import { Box } from '@chakra-ui/react'
import DOMPurify from 'dompurify'

export default function SearchResultListItem({ index, text, onSelected, published = true }) {
  return (
    <Box
      key={`search-result-item-${index}`}
      flexShrink={1}
      textAlign="left"
      mx={2}
      my={2}
      _hover={{
        cursor: 'pointer',
        bg: '#9663FC',
        color: 'white'
      }}
      p={1}
      onClick={onSelected}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(published ? text : text + ' (Unpublished)') // TODO:PUBLISHED - don't know what this is
      }}
    ></Box>
  )
}
