import { useListSubjectsQuery, useSubjectsForLessonPathQuery } from 'graphql/generated'
import { get } from 'lodash'
import Select from './select'

export default function SubjectSelect({ label, name, forPath = false, ...rest }) {
  const [subjectsAllResult] = useListSubjectsQuery()
  const [subjectsResult] = useSubjectsForLessonPathQuery()
  const subjectField = forPath ? 'data.subjectsForLessonPath' : 'data.subjects'
  const subRes = forPath ? subjectsResult : subjectsAllResult
  const subjects = get(subRes, subjectField, []).map((x) => ({
    value: x.id,
    name: x.name
  }))

  return <Select label={label} name={name} items={subjects} {...rest} />
}
