import { InputProps, TextEditor } from '@heytutor/common'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import FieldWrapper from './field-wrapper'

const SimpleRichEditor = ({ label, name, isRequired, helper }: InputProps) => {
  const { handleChange }: any = useFormikContext()
  const [text, setText] = useState<string>(undefined)

  return (
    <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
      <TextEditor
        defaultValue={text}
        onChange={(value) => {
          setText(value)
          handleChange(name)(value)
        }}
      />
    </FieldWrapper>
  )
}

export default SimpleRichEditor
